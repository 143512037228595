import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Section from '../../../component-elements/content-section';
import CmsTextComponent from '../../../content-elements/text';
import StructuredTextButton from '../../../content-elements/textButton';

const CmsMulticolumnBrand = (props) => {
  const {
    background,
    col1Headline,
    col1Text,
    col1Button,
    col2Headline,
    col2Text,
    col2Button,
    col2Wide,
  } = props;

  const col1TextRef = React.createRef();
  const col2TextRef = React.createRef();

  useEffect(() => {
    const col1textLength = col1TextRef?.current?.textContent?.length;
    const col2textLength = col2TextRef?.current?.textContent?.length;
    if (col1textLength > 500 && col2Wide !== true) {
      col1TextRef.current.classList.add('multicolumn-text');
    }
    if (col2textLength > 500 && col2Wide === true) {
      col2TextRef.current.classList.add('multicolumn-text');
    }
  }, [col1Text, col2Text]);

  const result = (data) => {
    if (data.value.document.children[0].children
      .filter((el) => (el.value?.length > 0))?.length === 0) {
      return null;
    }
    return data;
  };

  return (
    <Section mobileSwitch background={background}>
      <div className={`content-col ${col2Wide === true ? 'content-col-last col-lg-5' : 'content-col-first col-lg-10'}`}>
        {
          (col1Headline) && (
            <header className="section-header section-highlight-header">
              <CmsTextComponent content={col1Headline} />
            </header>
          )
        }
        <div ref={col1TextRef}>
          <CmsTextComponent content={col1Text} />
          {col1Button && (
            <StructuredTextButton content={col1Button} />
          )}
        </div>
      </div>
      {
        (result(col2Text) || result(col2Headline) || result(col2Button)) && (
        <div className={`content-col ${col2Wide === true ? 'content-col-first col-lg-10 offset-lg-1' : 'content-col-last col-lg-5 offset-lg-1'}`}>
          {
            (col2Headline) && (
              <header className="section-header section-highlight-header">
                <CmsTextComponent content={col2Headline} />
              </header>
            )
          }
          <div ref={col2TextRef}>
            <CmsTextComponent content={col2Text} />
            <StructuredTextButton content={col2Button} />
          </div>
        </div>
        )
      }
    </Section>
  );
};

CmsMulticolumnBrand.defaultProps = {
  col1Button: {},
  col2Text: {},
  col2Button: {},
  col2Headline: null,
  background: '',
  col2Wide: false,
};

CmsMulticolumnBrand.propTypes = {
  col1Headline: PropTypes.objectOf(PropTypes.object).isRequired,
  col1Text: PropTypes.objectOf(PropTypes.any).isRequired,
  col1Button: PropTypes.objectOf(PropTypes.any),
  col2Headline: PropTypes.objectOf(PropTypes.object),
  col2Text: PropTypes.objectOf(PropTypes.any),
  col2Button: PropTypes.objectOf(PropTypes.any),
  background: PropTypes.string,
  col2Wide: PropTypes.bool,
};

export default CmsMulticolumnBrand;
