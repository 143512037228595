import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper/core';
import ContentHeader from '../../../component-elements/contentHeaderBackground';
import * as ContentHeaderStyles from '../../../component-elements/contentHeaderBackground/component.module.scss';
import 'swiper/swiper.scss';
import * as TeasersStyle from './component.module.scss';
import t from '../../../lib/translate';

SwiperCore.use([Pagination, Navigation, Autoplay]);
const CategoryHeader = ({ headline = '', teaser = [] }) => (

  <ContentHeader className={ContentHeaderStyles.contentHeaderCategory}>
    {headline && (<h1>{headline}</h1>)}

    {teaser.length > 0 && (
      <div className="container-fluid category-slider">
        <div className="slider product-slider has-items-4">
          <span className="icon icon-left icon-gold swiper-button swiper-button-prev prev"><span className="sr-only">{t`pager_prev_label`}</span></span>
          <span className="icon icon-right icon-gold swiper-button swiper-button-next next"><span className="sr-only">{t`pager_next_label`}</span></span>
          <Swiper
            slidesPerView={teaser.length >= 4 ? 4 : teaser.length}
            spaceBetween={30}
            slidesPerGroup={1}
            loop
            watchOverflow
            loopedSlides={50}
            navigation={{
              prevEl: '.prev',
              nextEl: '.next',
            }}
            breakpoints={{
              1275: {
                slidesPerView: teaser.length >= 4 ? 4 : teaser.length,
              },
              1024: {
                slidesPerView: teaser.length >= 3 ? 3 : teaser.length,
              },
              767: {
                slidesPerView: teaser.length >= 2 ? 2 : teaser.length,
              },
              320: {
                slidesPerView: 1,
              },
            }}
            speed={1000}
            className="teaser-slider"
            autoplay={{
              delay: 2000,
              disableOnInteraction: true,
            }}
          >
            {teaser.map(({
              id,
              teaserName,
              teaserImage,
              category,
            }) => (
              <SwiperSlide
                key={id}
                className="swiper-slide"
                style={{
                  width: `calc((100% - 90px) / ${teaser.length})`,
                }}
              >
                <div className="teaser">
                  <a href={category.fullUrlPath} className={TeasersStyle.teaserLink}>
                    {teaserImage?.gatsbyImageData ? (
                      <GatsbyImage
                        loading="eager"
                        image={teaserImage.gatsbyImageData}
                        width={350}
                        height={350}
                        alt={teaserImage.alt}
                        as="span"
                      />
                    ) : (
                      <StaticImage
                        src="../../../images/default.png"
                        width={350}
                        height={350}
                        alt={teaserName || category.name}
                        as="span"
                      />
                    )}
                    <span className={`${TeasersStyle.teaserText} ${TeasersStyle.teaserTextFade}`}>
                      <span className={TeasersStyle.teaserTitle}>
                        {teaserName && <>{teaserName}</>}
                        {!teaserName && <>{category.name}</>}
                      </span>
                    </span>
                  </a>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    )}
  </ContentHeader>
);

CategoryHeader.defaultProps = {
  headline: '',
  teaser: [],
};

CategoryHeader.propTypes = {
  headline: PropTypes.string,
  teaser: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    teaserName: PropTypes.string,
    teaserImage: PropTypes.shape({
      gatsbyImageData: PropTypes.objectOf(PropTypes.any).isRequired,
      alt: PropTypes.string.isRequired,
    }),
    category: PropTypes.shape({
      name: PropTypes.string.isRequired,
      fullUrlPath: PropTypes.string.isRequired,
    }).isRequired,
  })),
};

export default CategoryHeader;
