import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import t from '../lib/translate';

import Layout from '../layouts/default';
import Helmet from '../components/helmet';
import Breadcrumb from '../component-elements/breadcrumb';
import CmsMulticolumnBrand from '../brands-component/content-elements/CmsMulticolumnBrand';
import CategoryHeader from '../components/shop-category/header';
import ProductGrid from '../components/shop-category/product-grid';

const FilterLandingpageTemplate = ({
  data: {
    datoCmsFilterLandingpage: {
      id,
      name,
      headline,
      content,
      metaRobots,
      metaCanonical,
      seoMetaTags,
      seo,
      category: {
        magentoCategory,
      },
    },
    allMagentoProduct: {
      totalCount: totalProductCount,
      nodes: products,
    },
  },
  pageContext: {
    lang,
    url,
    jr_brand: brandFilter,
    jr_material: materialFilter,
    jr_band_type: bandTypeFilter,
    jr_stone_kind: stoneKindFilter,
  },
  location,
}) => {
  const filterValues = {
    jr_brand: brandFilter !== '/.*/' ? brandFilter : undefined,
    jr_material: materialFilter !== '/.*/' ? materialFilter : undefined,
    jr_band_type: bandTypeFilter !== '/.*/' ? bandTypeFilter : undefined,
    jr_stone_kind: stoneKindFilter !== '/.*/' ? stoneKindFilter : undefined,
  };

  return (
    <Layout>
      <Breadcrumb
        items={[
          { id, name },
        ]}
      />

      <CategoryHeader
        headline={headline}
      />

      <ProductGrid
        staticFilters={[
          ...(
            Object.keys(filterValues)
              .filter((key) => filterValues[key] !== undefined)
              .map((key) => ({
                key,
                values: [filterValues[key].replace(/\//g, '')],
              }))
          ),
          {
            key: 'category_id',
            values: [magentoCategory.magento_id],
          },
        ]}
        items={products}
        initialItemTotal={totalProductCount}
        sortFields={magentoCategory.sortFields}
        lang={lang}
        location={location}
        breadcrumb={[
          { id, name, url },
        ]}
      />

      {content.map((c) => (
        <CmsMulticolumnBrand key={c.id} {...c} />
      ))}

      <Helmet
        tags={seoMetaTags}
        metaRobots={metaRobots}
        metaCanonical={metaCanonical}
        title={seo?.title}
        titleFallback={t('filterlandingpage_seo_title_fallback', name)}
        description={seo?.description}
        descriptionFallback={t('filterlandingpage_seo_description_fallback', name)}
        url={url}
      />
    </Layout>
  );
};

export default FilterLandingpageTemplate;

FilterLandingpageTemplate.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  pageContext: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export const query = graphql`
    query filterLandingpageQuery(
        $id: String!
        $lang: String!
        $magentoId: Int!
        $productsPerPage: Int!
        $jr_brand: String!
        $jr_material: String!
        $jr_band_type: String!
        $jr_stone_kind: String!
    ) {
        datoCmsFilterLandingpage(id: {eq: $id}, locale: {eq: $lang}) {
            id
            name
            
            metaCanonical
            metaRobots
            seoMetaTags {
                tags
            }
            seo {
                title
                description
            }
            
            headline
            content {
                ...ce_multicolumn_text
            }
        
            category {
                magentoCategory {
                    magento_id
                    sortFields {
                        label
                        value
                    }
                }
            }
        }
    
        allMagentoProduct(
            filter: {
                categories: { elemMatch: { id: { eq: $magentoId } } }
                locale: { eq: $lang }
                jr_brand: { regex: $jr_brand }
                jr_material: { regex: $jr_material }
                jr_band_type: { regex: $jr_band_type }
                jr_stone_kind: { regex: $jr_stone_kind }
            }
            limit: $productsPerPage
            sort: { fields: name, order: ASC }
        ) {
            totalCount
            nodes {
                ...shopProductListItem
            }
        }
    }
`;
